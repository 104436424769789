@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap);
:root {
  --nav-primary: #0a0a0a;
  --nav-textcolor: #fff;
}

.nav-bar {
  width: 100%;
  height: 85px;
  top: 0;
  background: var(--nav-primary);
  justify-content: space-evenly;
  z-index: 99;
  display: block;
  font-family: Roboto, sans-serif;
  color: #fff;
}

.nav-bar,
.nav-inner {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.nav-inner {
  position: relative;
  height: 100%;
  justify-content: space-between;
}

.nav-logo-container {
  position: relative;
  width: 75px;
  height: 75px;
}

.nav-logo-container img {
  position: relative;
  width: 100%;
  height: 100%;
}

.nav-items {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.nav-socials {
  width: auto;
  height: 85px;
}

.nav-social-link {
  height: 85px;
  line-height: 99px;
  position: relative;
  color: #fff;
  margin-left: 15px;
}

.nav-social-icon {
  width: 28px;
  height: 28px;
}

.hamburger-button-container {
  position: fixed;
  top: 1rem;
  right: 1rem;
  z-index: 99;
  width: 4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.activate-nav-button,
.deactivate-nav-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
  z-index: 99;
  background-color: #00000000;
  border: 0rem;
  width: 4rem;
  height: 4rem;
}

.activate-nav-button svg,
.deactivate-nav-button svg {
  z-index: 99;
  height: 3rem;
  width: 3rem;
}

.nav-sub-container {
  position: absolute;
  background: var(--nav-primary);
  transition: 0.1s ease-in-out;
  transition-delay: 0.2s;
  transform: translateY(20px);
  visibility: hidden;
  left: 0;
  opacity: 0;
  top: 100%;
  padding-right: 20px;
}

.nav-link,
.nav-link-container {
  position: relative;
  padding-left: 1rem;
}

.nav-link {
  display: block;
  color: var(--nav-textcolor);
  padding: 0;
  margin: 0;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 17px;
  line-height: 75px;
}

.nav-chevron {
  position: absolute;
  top: 65%;
  left: calc(50% - 12px);
  -webkit-margin-before: 0;
  margin-block-start: 0;
  -webkit-margin-after: 0;
  margin-block-end: 0;
}

.nav-link:active {
  text-decoration: none;
  outline: none;
  border: none;
}

.nav-sub-container .nav-link-container .nav-link {
  padding: 0;
  margin: 0;
  position: relative;
  color: #fff;
  line-height: 30px;
}

.nav-bar-container {
  position: sticky;
  top: 0;
  z-index: 2;
}

.namebanner {
  background-color: #000000;
  border-bottom: solid green 4px;
  align-items: center;
  display: flex;
}

.namebanner #namebanner-image {
  padding-top: 1rem;
  padding-bottom: 1rem;
  max-width: 50rem;
  height: auto;
  width: 100%;
  display: block;
  width: calc(100% - 9rem);
  padding: 0rem;
  margin-right: auto;
  margin-left: auto;
}

.namebanner .nav-link {
  display: none;
}

.nav-link-dropdown-container {
  text-align: center;
  position: absolute;
  background: var(--nav-primary);
  white-space: nowrap;
}

.nav-link-dropdown-container .nav-link {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

/* Mobile responsivess */
@media screen and (max-width: 1200px) {
  .nav-bar,
  .nav-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all 0.5s ease-in-out;
    position: fixed;
  }

  .nav-items {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    text-align: center;
  }

  .nav-bar {
    height: 100vh;
  }

  .nav-logo-container {
    margin-top: 3rem;
  }

  .nav-link,
  .nav-link-container {
    padding-left: 0rem;
  }

  .nav-link {
    padding: 0;
    margin: 0;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 17px;
    line-height: 50px;
  }

  .nav-social-link {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .activate-nav-button,
  .deactivate-nav-button {
    display: block;
  }

  .nav-link-dropdown-container {
    position: inherit;
    text-align: left;
    margin-left: 2rem;
    border-left: 3px solid white;
  }

  .nav-link-dropdown-container .nav-link {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .namebanner p {
    display: inline;
  }

  .namebanner .nav-link {
    display: flex;
  }

  .namebanner #namebanner-image {
    margin-right: 0rem;
    margin-left: 0rem;
  }

  .nav-link-container .parent-link {
    text-align: left;
  }
}
