body {
  background: rgb(13, 13, 13);
  margin: 0;
  overflow-x: hidden;
}

.bannerImage {
  width: 100%;
}

@media only screen and (max-width: 1024px) {
  .bannerImage {
    width: auto;
  }
}

.page-banner-container {
  border: #afafaf;
  border-style: solid;
  border-width: 0.1rem;
  border-top: 0;
}

.page {
    margin-left: auto;
    margin-right: auto;
    max-width: 85rem;
    background: #191919;
    padding-left: 5rem;
    padding-right: 5rem;
    min-height: 85vh;

    border-right: #afafaf;
    border-left: #afafaf;
    border-style: solid;
    border-width: 0.1rem;
}

@media only screen and (max-width: 1024px) {
  .page {
    border-width: 0;
    padding-left: 2px;
    padding-right: 2px;
  }
}

.partner-widget-item {
  max-height: 50rem;
}

.partners-title {
  text-align: center;
}

.construction {
  text-align: center;
  font-size: large;
  color: brown;
}

.footer {
    left: 0;
    width: 100%;
    min-height: 15rem;
    padding-top: 4rem;
    padding-bottom: 12rem;
    color: white;
    text-align: left;
    bottom: 0;
    border-top:solid green 4px;
    background-color: #0a0a0a;
}

.footer-container {
    display: flex;
    flex-direction: row;
    height: 100%;
    max-width: 110rem;
    width: auto;
    margin-left: auto;
    margin-right: auto;
}

@media only screen and (max-width: 1024px) {
  .footer-container {
    flex-direction: column;
  }
}

.footer-item {
    padding-left: 5rem;
    padding-right: 5rem;
}

.footer img{
    padding-top: 7rem;
    width: 14rem;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.partner-widget-item {
  transition: transform .2s;
}

.partner-widget-item:hover {
  transform: scale(1.5);
}

.nav-link, .nav-social-link svg {
  transition: transform .2s;
  padding-left: 0.2rem;
}

.nav-link:hover, .nav-social-link svg:hover {
  transform: scale(1.2);
}

.page-text a {
  display: inline-block;
  position: relative;
  text-decoration: none;
  color: #fd6a02;
  background: transparent;
  padding-left: 20px;
  padding-right: 20px;
  width: auto;
  height: 55px;
  text-align: center;
  line-height: 55px;
  border-radius: 5px;
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 30px;
  text-transform: uppercase;
  border: 3px solid #fd6a02;
  z-index: 0;
  transition: 0.2s ease-in-out;
}

.page-text a:before {
  content: " ";
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 100%;
  background: #fd6a02;
  z-index: -1;
  transition: 0.2s ease-in-out;
}

.page-text a:hover {
  color: #fff;
}

.page-text a:hover:before {
  width: 100%;
}

.page-title-image img{
  width: 70rem;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  padding-top: 5rem;
}
.page-title-image{
  display: flex;
  justify-content: center;
  align-items: center;
}

h1.not-found {
  margin-top: 100px;
  font-size: 40px;
}

p.not-found {
  font-size: 20px;
}

.not-found {
  color: #fff;
  text-align: center;
}

.mapImage {
  padding-top: 5rem;
  padding-bottom: 5rem;
  width: 60rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 1200px) {
  .page-title-image img{
    width: 40rem;
    padding-top: 3rem;
  }
  .mapImage {
    width: 40rem;
  }
}

@media only screen and (max-width: 800px) {
  .page-title-image img{
    width: 20rem;
    padding-top: 3rem;
  }

  .mapImage {
    width: 20rem;
  }
}